import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="unordered">
            <strong>
              <strong>Contacts</strong>
            </strong>
          </h3>
          <p>
            Curious about the prices? Or anything else? Shoot me a message and I will send you all the information.
          </p>
          <ul>
            <li>anetjursova@gmail.com</li>
            <li>+420 722 456 001</li>
          </ul>
          <h3 id="unordered">
            <strong>
              <strong>Kontakt</strong>
            </strong>
          </h3>
          <p>Zajímá Vás cena? Nebo cokoliv jiného? Pošlete mi zprávu a já Vám zašlu veškeré informace.</p>
          <ul>
            <li>anetjursova@gmail.com</li>
            <li>+420 722 456 001</li>
          </ul>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    smallPic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medPic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    largePic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactsPage location={props.location} data={data} {...props} />
    )}
  />
)
